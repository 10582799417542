// import { getAuth } from 'firebase/auth';

const envApiHost =
    process.env.REACT_APP_API_HOST || 'https://api.shoothub.io/api';

const API_HOST =
    window.location.hostname === 'localhost'
        ? 'http://localhost:3002/api'
        : envApiHost;

// const auth = getAuth();

// async function call(method: string, path: string, data?: any) {
//     const authtoken = await auth.currentUser?.getIdToken();
//     if (!authtoken) {
//         console.error('authtoken not present');
//         return;
//     }

//     const url = API_HOST + path;
//     const res = await fetch(url, {
//         method,
//         body: data ? JSON.stringify(data) : undefined,
//         headers: {
//             'X-Auth-Token': authtoken,
//             'Content-Type': 'application/json',
//         },
//     });

//     if (res.status !== 200) {
//         throw await res.text();
//     }

//     const resData = await res.json();
//     return resData;
// }

export async function getShoot(id: string, password: string = '') {
    const timestamp = Date.now();
    let path = API_HOST + `/shoots/${id}?key=${password}&tx=${timestamp}`;

    const res = await fetch(path);
    const data = await res.json();

    return data;
}
