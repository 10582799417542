import { VariantSet } from '../../models';
import { variantToImageUrl } from '../../utils';

import './ThumbnailScroller.scss';

interface ThumbnailScrollerProps {
    selectedVariantId?: string;
    selectedComparisonVariantId?: string;
    variants: VariantSet;
    currentPath: string;
    shootId: string;
    onClick?: (id: string) => void;
    onClickCompare?: (id: string) => void;
}
export default function ThumbnailScroller({
    selectedVariantId,
    selectedComparisonVariantId,
    variants,
    currentPath,
    onClick,
    onClickCompare,
    shootId,
}: ThumbnailScrollerProps) {
    return (
        <div className="ThumbnailScroller">
            <div className="scroll-wrapper">
                {Object.entries(variants)
                    .filter(([k, d]) =>
                        currentPath ? d.imagePath === currentPath : true
                    )
                    .sort(([ka, da], [kb, db]) => {
                        const a = da.kVariantKey_VariantName;
                        const b = db.kVariantKey_VariantName;
                        return b.localeCompare(a);
                    })
                    .map(([k, d]) => {
                        const height = 150;
                        const width =
                            (height *
                                d.kVariantKey_Properties
                                    .kVariantProperty_Width) /
                            d.kVariantKey_Properties.kVariantProperty_Height;
                        return (
                            <div
                                className={
                                    'image ' +
                                    (selectedVariantId === k
                                        ? 'selected_variant '
                                        : '') +
                                    (selectedComparisonVariantId === k
                                        ? 'selected_comparison '
                                        : '')
                                }
                                key={k}
                                style={{
                                    width,
                                    height,
                                }}
                                onClick={() => {
                                    onClick && onClick(k);
                                }}
                            >
                                <img
                                    src={variantToImageUrl(
                                        k,
                                        shootId,
                                        d.revision
                                    )}
                                    alt={d.kVariantKey_VariantName}
                                    className="thumbnail"
                                />
                                <div
                                    className={
                                        'colorTag colorTag_' +
                                        d.kVariantKey_Properties.kVariantProperty_Colortag.toString()
                                    }
                                ></div>

                                <div className="stars">
                                    {'★'.repeat(
                                        d.kVariantKey_Properties
                                            .kVariantProperty_Rating
                                    )}
                                </div>

                                <div
                                    className="compare"
                                    onClick={(e) => {
                                        onClickCompare && onClickCompare(k);
                                        e.stopPropagation();
                                    }}
                                >
                                    <img
                                        src="/assets/images/swap.svg"
                                        alt="Compare Variant"
                                    />
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}
