export default function Home() {
    return (
        <div className="page page_home">
            <img
                src="/assets/images/shoot-hub-white.svg"
                alt="Shoot Hub"
                className="logo"
            />
        </div>
    );
}
