const s3BaseUrl = 'https://shoothub.s3.eu-west-2.amazonaws.com/data/variants/';

export function variantToImageUrl(
    id: string,
    shootId: string,
    revision?: number | string | undefined
) {
    return s3BaseUrl + shootId + '/' + id + '.jpg?revision=' + revision;
}

const C1ColorRating = {
    none: 'rgba(0,0,0,0)',
    red: '#BE0B0B',
    orange: '#BE5B0A',
    yellow: '#BFBE0B',
    green: '#0CBF0C',
    blue: '#0B5EC0',
    pink: '#BE0BC0',
    purple: '#5D0BBF',
};

const C1ColorTagToName = [
    'none',
    'red',
    'orange',
    'yellow',
    'green',
    'blue',
    'pink',
    'purple',
];

export function colorTagToColor(tag: number) {
    // @ts-ignore
    if (C1ColorTagToName[tag] && C1ColorRating[C1ColorTagToName[tag]]) {
        // @ts-ignore
        return C1ColorRating[C1ColorTagToName[tag]];
    }

    return C1ColorRating.none;
}

export function filenameWithoutExtension(filename: string): string {
    const parts = filename.split('.');
    if (parts.length <= 1) return filename;
    return parts.slice(0, -1).join('.');
}
