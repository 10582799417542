import { Variant } from '../../models';
import Thumbnail from '../Thumbnail/Thumbnail';

interface GridProps {
    variants: { [key: string]: Variant };
    currentPath: string;
    onClick: (id: string) => void;
    shootId: string;
}

export default function Grid({
    variants,
    currentPath,
    onClick,
    shootId,
}: GridProps) {
    return (
        <div className="Grid">
            <div className="image-grid">
                {Object.entries(variants)
                    .filter(([k, d]) =>
                        currentPath ? d.imagePath === currentPath : true
                    )
                    .sort(([ka, da], [kb, db]) => {
                        const a =
                            da.kVariantKey_VariantName +
                            (db.kVariantKey_VariantNo + 1)
                                .toString()
                                .padStart(4, '0');
                        const b =
                            db.kVariantKey_VariantName +
                            (da.kVariantKey_VariantNo + 1)
                                .toString()
                                .padStart(4, '0');
                        return b.localeCompare(a);
                    })
                    .map(([k, d]) => (
                        <Thumbnail
                            colorTag={
                                d.kVariantKey_Properties
                                    .kVariantProperty_Colortag
                            }
                            key={k}
                            id={k}
                            shootId={shootId}
                            revision={d.revision}
                            filename={d.kVariantKey_VariantName}
                            stars={
                                d.kVariantKey_Properties.kVariantProperty_Rating
                            }
                            cloneId={d.kVariantKey_VariantNo}
                            onClick={onClick}
                        />
                    ))}
            </div>
        </div>
    );
}
