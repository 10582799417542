import './FollowLatestButton.scss';

interface FollowLatestButtonProps {
    isFollowing: boolean;
    onClick: () => void;
}
export default function FollowLatestButton({
    isFollowing,
    onClick,
}: FollowLatestButtonProps) {
    return (
        <div
            className={'FollowLatestButton ' + (isFollowing ? 'following' : '')}
            onClick={onClick}
        >
            Follow
        </div>
    );
}
