import { useState } from 'react';

export interface PasswordInputProps {
    onSubmit: (password: string) => void;
}
export default function PasswordInput({ onSubmit }: PasswordInputProps) {
    const [password, setPassword] = useState('');

    return (
        <div className="PasswordInput">
            <form
                action=""
                onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit(password);
                }}
            >
                <label htmlFor="shootpassword">Password</label>
                <input
                    id="shootpassword"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </form>
        </div>
    );
}
