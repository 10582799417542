import { useEffect, useState } from 'react';
import { filenameWithoutExtension, variantToImageUrl } from '../../utils';

import './Thumbnail.scss';

interface ThumbnailProps {
    id: string;
    revision: string;
    stars: number;
    filename: string;
    colorTag: number;
    onClick?: (id: string) => void;
    shootId: string;
    cloneId: number;
}

export default function Thumbnail({
    id,
    revision,
    stars,
    filename,
    colorTag,
    onClick = () => {},
    shootId,
    cloneId,
}: ThumbnailProps) {
    const [url, setUrl] = useState('');
    const [aspectRatio, setAspectRatio] = useState(1);

    useEffect(() => {
        const img = new Image();
        const newUrl = variantToImageUrl(id, shootId, revision);

        img.onload = () => {
            setUrl(newUrl);
            setAspectRatio(img.height / img.width);
        };
        img.src = newUrl;
    }, [id, revision, shootId]);

    return (
        <div className="Thumbnail" onClick={() => onClick(id)}>
            <div className="padding">
                <div className="img">
                    <img
                        src={url}
                        alt=""
                        style={{
                            [aspectRatio < 1 ? 'width' : 'height']: '100%',
                        }}
                    />
                </div>
                <div className="info">
                    <div className="rating">
                        <span className={'color colorTag_' + colorTag}></span>
                        {'★'.repeat(stars)}
                    </div>
                    <div className="name" title={filename}>
                        {filenameWithoutExtension(filename)}{' '}
                        {cloneId > 0 && (
                            <div className="cloneId">{cloneId}</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
