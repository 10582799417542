import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import Shoot from './pages/Shoot';
import Login from './pages/Login';

import './App.scss';

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/:id" component={Shoot} />
            </Switch>
        </Router>
    );
}

export default App;
