import {
    collection,
    doc,
    getFirestore,
    onSnapshot,
    orderBy,
    query,
    where,
} from 'firebase/firestore';

import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getShoot } from '../api';
import FocusImage from '../components/FocusImage/FocusImage';
import FollowLatestButton from '../components/FollowLatestButton/FollowLatestButton';
import Grid from '../components/Grid/Grid';
import PasswordInput from '../components/PasswordInput/PasswordInput';
import PathSelector from '../components/PathSelector/PathSelector';
import ThumbnailScroller from '../components/ThumbnailScroller/ThumbnailScroller';
import { Variant } from '../models';

const db = getFirestore();

type ShowingType = 'latest' | 'selected' | 'compare';
type ViewType = 'grid' | 'detail';

let showingType: ShowingType = 'latest';

export default function Shoot() {
    const { id: shootId } = useParams<{ id: string }>();

    const [pilotId, setPilotId] = useState('');
    const [shootPassword, setShootPassword] = useState<string | null>('');
    const [existingPasswordTried, setExistingPasswordTried] = useState(false);

    useEffect(() => {
        const password = window.localStorage.getItem('pw_' + shootId) || '';
        setShootPassword(password);
    }, [shootId]);

    // Password screen
    useEffect(() => {
        if (shootPassword === null) return;

        (async () => {
            const shoot = await getShoot(shootId, shootPassword);
            if (shoot.pilotId) {
                setPilotId(shoot.pilotId);
                window.localStorage.setItem('pw_' + shootId, shootPassword);
            }
            setExistingPasswordTried(true);
        })();
    }, [shootPassword, shootId]);

    const [forceRerenderCount, setForceRerenderCount] = useState(0);
    const [variants, setVariants] = useState<{ [key: string]: Variant }>({});
    const [sessionId, setSessionId] = useState('');
    const [currentPath, setCurrentPath] = useState('');
    const [availablePaths, setAvailablePaths] = useState<Set<string>>(
        new Set()
    );

    const [currentlyShowing, setCurrentlyShowing] = useState('');
    const [comparingTo, setComparingTo] = useState('');
    const [currentView, setCurrentView] = useState<ViewType>('grid');

    // const [C1_selectedVariants, setC1_selectedVariants] = useState<string[]>(
    //     []
    // );

    const handleArrowNavigation = useCallback(
        (key: 'ArrowLeft' | 'ArrowRight') => {
            const list = Object.entries(variants)
                .filter(([k, d]) =>
                    currentPath ? d.imagePath === currentPath : true
                )
                .sort(([ka, da], [kb, db]) => {
                    const a = da.kVariantKey_VariantName;
                    const b = db.kVariantKey_VariantName;
                    return b.localeCompare(a);
                });

            const currentIndex = list.findIndex(
                ([k, v]) => k === currentlyShowing
            );

            if (currentIndex === -1) return;

            if (key === 'ArrowRight' && list.length - 1 > currentIndex + 1) {
                setCurrentlyShowing(list[currentIndex + 1][0]);
            }
            if (key === 'ArrowLeft' && currentIndex > 0) {
                setCurrentlyShowing(list[currentIndex - 1][0]);
            }
        },
        [currentPath, variants, currentlyShowing, setCurrentlyShowing]
    );

    const handleEscape = useCallback(() => {
        console.log('comparingto', comparingTo);
        if (comparingTo !== '') {
            setComparingTo('');
            return;
        }

        if (currentView !== 'grid') {
            setCurrentView('grid');
            setCurrentlyShowing('');
        }
    }, [currentView, comparingTo, setComparingTo, setCurrentView]);

    useEffect(() => {
        const callback = (e: KeyboardEvent) => {
            switch (e.key) {
                case 'ArrowLeft':
                case 'ArrowRight':
                    handleArrowNavigation(e.key);
                    break;

                case 'Escape':
                    handleEscape();
                    break;

                case 'g':
                    if (currentView === 'detail') setCurrentView('grid');
                    if (currentView === 'grid') setCurrentView('detail');
                    break;

                default:
                    break;
            }
        };
        window.addEventListener('keydown', callback);
        return () => {
            window.removeEventListener('keydown', callback);
        };
    }, [handleArrowNavigation, handleEscape, currentView]);

    useEffect(() => {
        if (!pilotId) return;

        const unsubscribe = onSnapshot(
            doc(db, `pilotHub/${pilotId}`),
            (docRef) => {
                const shoot = docRef.data();
                if (!shoot) {
                    return;
                }
                setSessionId(shoot.sessionId);
                // setC1_selectedVariants(shoot.selectedVariantIds);
            }
        );
        return unsubscribe;
    }, [pilotId]);

    useEffect(() => {
        if (!sessionId) return;

        const q = query(
            collection(db, `pilotHub/${pilotId}/variants`),
            where('sessionId', '==', sessionId),
            orderBy('kVariantKey_VariantName')
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                const doc = change.doc;
                const v = doc.data() as Variant;

                if (change.type === 'added' || change.type === 'removed') {
                    setVariants((currVariants) => ({
                        ...currVariants,
                        [doc.id]: v,
                    }));

                    setAvailablePaths((paths) => {
                        return paths.add(v.imagePath);
                    });

                    if (showingType === 'latest' && change.type === 'added') {
                        setCurrentlyShowing(doc.id);
                    }
                }

                if (change.type === 'modified') {
                    console.log('modified');
                    setVariants((currVariants) => {
                        return { ...currVariants, [doc.id]: v };
                    });
                }

                if (change.type === 'removed') {
                    console.log('remove');
                    setVariants((currVariants) => {
                        const { [doc.id]: removed, ...newObj } = currVariants;
                        return newObj;
                    });
                }
            });
        });

        return unsubscribe;
    }, [pilotId, sessionId, setCurrentlyShowing]);

    // console.log('currentlyShowing', currentlyShowing);

    const showingVariant = variants[currentlyShowing];
    const comparingVariant = variants[comparingTo];

    if (!pilotId && existingPasswordTried) {
        return (
            <PasswordInput
                onSubmit={(password) => {
                    setShootPassword(password);
                }}
            />
        );
    }

    return (
        <div className={'page page_shoot page_shoot__' + currentView}>
            <img
                src="/assets/images/shoot-hub-white.svg"
                alt="Shoot Hub"
                className="logo"
                onClick={() => {
                    setCurrentView('grid');
                    setCurrentlyShowing('');
                }}
            />

            {currentView === 'grid' && (
                <>
                    <Grid
                        shootId={pilotId}
                        currentPath={currentPath}
                        variants={variants}
                        onClick={(id) => {
                            showingType = 'selected';
                            setCurrentlyShowing(id);
                            setCurrentView('detail');
                        }}
                    />

                    <PathSelector
                        currentPath={currentPath}
                        paths={Array.from(availablePaths)}
                        onSelectPath={(d) => {
                            setCurrentPath(d);
                        }}
                    />
                </>
            )}

            <FollowLatestButton
                onClick={() => {
                    if (showingType !== 'latest') {
                        showingType = 'latest';
                    } else {
                        showingType = 'selected';
                    }
                    setForceRerenderCount(forceRerenderCount + 1);
                }}
                isFollowing={showingType === 'latest'}
            />

            {currentView === 'detail' && (
                <>
                    <ThumbnailScroller
                        shootId={pilotId}
                        selectedComparisonVariantId={comparingTo}
                        selectedVariantId={currentlyShowing}
                        variants={variants}
                        currentPath={currentPath}
                        onClick={(id) => {
                            showingType = 'selected';
                            setCurrentlyShowing(id);
                        }}
                        onClickCompare={(id) => {
                            showingType = 'compare';
                            setComparingTo(id);
                        }}
                    />

                    <PathSelector
                        currentPath={currentPath}
                        paths={Array.from(availablePaths)}
                        onSelectPath={(d) => {
                            setCurrentPath(d);
                        }}
                    />

                    <div className="main-container">
                        {showingVariant && (
                            <div className="primary-image">
                                <FocusImage
                                    variant={showingVariant}
                                    id={currentlyShowing}
                                    shootId={pilotId}
                                />
                            </div>
                        )}
                        {comparingTo && (
                            <>
                                <div className="spacer"></div>
                                <div className="comparison-image">
                                    <FocusImage
                                        variant={comparingVariant}
                                        id={comparingTo}
                                        shootId={pilotId}
                                    />
                                    <div
                                        className="close"
                                        onClick={() => {
                                            setComparingTo('');
                                        }}
                                    >
                                        X Close
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    );
}
