import { Variant } from '../../models';
import { variantToImageUrl } from '../../utils';

import './FocusImage.scss';

export interface FocusImageProps {
    id: string;
    shootId: string;
    variant: Variant;
}

export default function FocusImage({ id, shootId, variant }: FocusImageProps) {
    return (
        <div className="FocusImage">
            <img
                src={variantToImageUrl(id, shootId, variant.revision)}
                className="image"
                alt=""
            />
            <div className="filename">{variant.kVariantKey_VariantName}</div>
            <div className="exifInfo">
                <div className="iso">
                    ISO {variant.kVariantKey_Properties.kVariantProperty_ISO}
                </div>
                <div className="shutter">
                    {
                        variant.kVariantKey_Properties
                            .kVariantProperty_ShutterSpeed
                    }
                </div>
                <div className="aperture">
                    {variant.kVariantKey_Properties.kVariantProperty_Aperture}
                </div>
                <div className="focalLength">
                    {
                        variant.kVariantKey_Properties
                            .kVariantProperty_FocalLength
                    }
                </div>
            </div>
        </div>
    );
}
