import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AppState {
    myOption: boolean;
}

const initialState: AppState = {
    myOption: false,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setOption: (state, action: PayloadAction<boolean>) => {
            state.myOption = action.payload;
        },
    },
});

export const { setOption } = appSlice.actions;
export default appSlice.reducer;
