import './PathSelector.scss';

export interface PathSelectorProps {
    currentPath: string;
    paths: string[];
    onSelectPath: (path: string) => void;
}

export default function PathSelector({
    currentPath,
    paths,
    onSelectPath,
}: PathSelectorProps) {
    return (
        <div className="PathSelector">
            <div className="pathList">
                <div
                    onClick={() => onSelectPath('')}
                    className={'path ' + (currentPath === '' && 'selected')}
                >
                    All
                </div>
                {paths.sort().map((d) => (
                    <div
                        className={'path ' + (d === currentPath && 'selected')}
                        key={d}
                        onClick={() => onSelectPath(d)}
                    >
                        {d}
                    </div>
                ))}
            </div>
            <div className="currentPath">
                <img src="/assets/images/FolderOpen.svg" alt="Folder Icon" />
                {currentPath || 'All'}
            </div>
        </div>
    );
}
